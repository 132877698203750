/* eslint-disable import/no-unresolved */
import React from 'react';

import styles from '@/components/homepage/Homepage.module.css';

export const ElectionWire = () => {
  return (
    <a className={styles.electionWireContainer} href='https://election.dailywire.com' rel='noreferrer' target='_blank'>
      <svg
        className={styles.desktopBanner}
        fill='none'
        height='22'
        viewBox='0 0 132 22'
        width='132'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          clipRule='evenodd'
          d='M9.96901 14.2997C9.96901 12.7691 9.28732 11.4207 8.08832 10.6009C7.26179 10.054 6.06309 9.7261 4.67862 9.7261C4.20287 9.7261 3.74851 9.76246 3.37634 9.79913V19.0373C3.37634 19.11 3.4179 19.1464 3.47993 19.1464C3.81054 19.183 4.2652 19.2011 4.63706 19.2011C5.87701 19.2011 7.09648 18.8915 7.98534 18.2538C9.16295 17.4154 9.96901 16.122 9.96901 14.318V14.2997ZM13.2139 14.2269C13.2139 16.8871 11.974 18.9279 9.88612 20.258C8.39837 21.2238 6.4144 21.6611 4.12029 21.6611C3.2522 21.6611 2.3224 21.6428 1.66117 21.6247C1.10292 21.6064 0.483257 21.5881 0.0078125 21.5517V21.4606C0.297174 21.2963 0.297174 20.6225 0.297174 15.3202V13.3155C0.297174 8.37798 0.297174 7.75862 0.0285903 7.6309V7.44848C0.462479 7.43046 1.10292 7.37576 1.95023 7.35712C2.81831 7.32107 3.58312 7.30273 4.40995 7.30273C6.72423 7.30273 8.50165 7.70332 9.86565 8.45071C12.2218 9.70807 13.2139 11.9857 13.2139 14.2086V14.2269Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M23.9273 16.6136L23.9065 16.5589C22.7286 13.8437 22.4389 13.0786 22.2739 12.55H22.2531C22.067 13.0786 21.7572 13.8437 20.538 16.5589L20.5173 16.6136H23.9273ZM19.5872 18.7816C18.7194 20.6406 18.3888 21.3874 18.5953 21.4968V21.6062H16.7971H14.958V21.4968C15.1853 21.3874 15.578 20.6406 18.2027 15.3016L22.1706 7.13843H22.6253L26.5312 15.32C29.0939 20.6223 29.4655 21.3874 29.6934 21.4968V21.6062H27.8745H25.7872V21.4968C25.9938 21.3874 25.6631 20.6223 24.8366 18.7816H19.5872Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M36.9007 15.3202C36.9007 20.6228 36.9007 21.2785 37.1898 21.4973V21.6063H35.3919H33.5115V21.4786C33.8005 21.2785 33.8005 20.6228 33.8005 15.3385V13.3154C33.8005 8.37793 33.8005 7.70327 33.5323 7.52177V7.39404H35.3509H37.1693V7.52177C36.9007 7.70327 36.9007 8.3596 36.9007 13.3154V15.3202Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M43.2983 13.3152C43.2983 8.37775 43.2983 7.70308 43.03 7.52128V7.39355H44.8896H46.6463V7.52128C46.3777 7.72142 46.3777 8.37775 46.3777 13.3152V15.283V19.1278C46.3777 19.2552 46.4189 19.2922 46.5638 19.2922H49.9118C50.3869 19.2922 50.635 19.2739 50.9039 19.2375C51.1309 19.2008 51.3998 19.1278 51.4618 19.0551H51.6268C51.6061 20.1484 51.6061 20.6223 51.5856 21.6062H47.5761H43.03V21.4601C43.2983 21.3147 43.2983 20.6039 43.2983 15.32V13.3152Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M61.2282 15.7394V16.231C61.2282 20.5861 61.2282 21.3512 61.5179 21.4973V21.6063H59.7197H57.839V21.4786C58.1283 21.3332 58.1283 20.5861 58.1283 16.2677V15.8851L56.6614 13.5708C53.3543 8.34157 52.9409 7.64857 52.6519 7.50313V7.39404H54.6771H56.6198V7.50313C56.3922 7.63085 56.8676 8.39627 58.0254 10.291C58.6242 11.3116 59.2858 12.3862 59.6992 13.1336H59.7404C60.1331 12.3862 60.7735 11.3843 61.4143 10.3457C62.613 8.39627 63.0884 7.59418 62.8816 7.50313V7.39404H64.9276H66.6017V7.50313C66.3539 7.64857 65.9613 8.32324 62.613 13.5708L61.2282 15.7394Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M86.727 21.8795H86.2931C82.4284 14.5367 81.9325 13.4434 81.5604 12.5142H81.5396C81.1677 13.4618 80.651 14.5734 76.7038 21.8795H76.2697L73.3354 13.0425C71.7645 8.37793 71.558 7.64888 71.3096 7.50313V7.39404H73.1698H75.03V7.50313C74.7614 7.64888 75.03 8.3596 76.1456 11.8946C76.6626 13.5525 76.9312 14.4634 77.2205 15.5386H77.2826C77.696 14.5547 78.1507 13.6075 79.0184 11.8765C79.9281 10.2 80.8166 8.68777 81.4363 7.53949H81.8497C82.4697 8.66974 83.2757 10.1272 84.3299 11.9309C85.0944 13.5165 85.5903 14.5367 86.0037 15.4659H86.0453C86.3344 14.3726 86.5825 13.4618 87.0371 12.0043C88.153 8.34157 88.4427 7.63085 88.1738 7.50313V7.39404H89.9097H91.625V7.50313C91.3772 7.66721 91.1496 8.3596 89.6206 13.0425L86.727 21.8795Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M99.5806 15.3202C99.5806 20.6228 99.5806 21.2785 99.8697 21.4973V21.6063H98.0718H96.1914V21.4786C96.4805 21.2785 96.4805 20.6228 96.4805 15.3385V13.3154C96.4805 8.37793 96.4805 7.70327 96.2122 7.52177V7.39404H98.0309H99.8492V7.52177C99.5806 7.70327 99.5806 8.3596 99.5806 13.3154V15.3202Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M112.525 11.6936C112.525 10.3816 111.554 9.61644 110.004 9.61644C109.652 9.61644 109.363 9.63447 109.177 9.6528V13.9899C109.342 14.0076 109.57 14.0076 109.776 14.0076C110.355 14.0076 110.933 13.8621 111.347 13.6434C111.926 13.3519 112.525 12.7319 112.525 11.7119V11.6936ZM106.242 13.3152C106.242 8.37745 106.242 7.61234 105.994 7.53901V7.42992C106.346 7.41128 106.924 7.39356 107.503 7.37523C107.999 7.35689 108.908 7.33887 109.446 7.33887C110.624 7.33887 111.636 7.42992 112.546 7.68506C113.827 8.04928 115.584 9.10589 115.584 11.5299C115.584 13.2245 114.592 14.3175 113.848 14.7914C113.455 15.0645 113 15.3197 112.649 15.4291V15.4654C112.938 15.8296 113.29 16.2675 116.389 20.0573C116.886 20.6583 117.526 21.3874 117.733 21.4968V21.6059H115.956H114.633C114.447 21.6059 114.178 21.5875 113.972 21.5328C112.773 19.9476 111.367 18.0892 109.735 15.976H109.177V16.7045C109.177 20.6223 109.198 21.3874 109.425 21.4968V21.6059H107.731H105.994V21.4968C106.242 21.3874 106.242 20.6223 106.242 15.3197V13.3152Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M122.721 13.3152C122.721 8.37775 122.721 7.61233 122.474 7.50294V7.39355H126.731H130.926C130.926 8.21367 130.906 8.85136 130.906 9.926H130.781C130.637 9.72586 129.707 9.67117 128.446 9.67117H125.801V13.2605H128.425C129.707 13.2605 130.099 13.2425 130.244 13.1154H130.348V14.3538V15.6845H130.244C130.099 15.5381 129.686 15.5201 128.467 15.5201H125.801V18.9637C125.801 19.1278 125.822 19.2192 125.946 19.2922C127.02 19.3099 128.446 19.3099 129.397 19.2922C130.265 19.2922 130.823 19.2552 130.988 19.0731H131.112C131.092 20.1667 131.092 20.7677 131.092 21.6062H127.062H122.453V21.4968C122.721 21.3874 122.721 20.6223 122.721 15.32V13.3152Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path clipRule='evenodd' d='M0 1.76888H49.2922V1.31055H0V1.76888Z' fill='#0A0909' fillRule='evenodd' />
        <path
          clipRule='evenodd'
          d='M82.5273 1.76888H131.12V1.31055H82.5273V1.76888Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M56.5816 0.4862H56.0258C55.8972 0.4862 55.8037 0.501784 55.7609 0.548534H55.7297V-0.000244141H56.6672H57.0565H58.0132V0.548534H57.9863C57.9355 0.501784 57.8457 0.4862 57.7217 0.4862H57.1613V1.69192C57.1613 2.82828 57.1613 3.00673 57.2199 3.01131V3.03423H56.8774H56.5272V3.01131C56.5816 2.97984 56.5816 2.82401 56.5816 1.69192V0.4862Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M67.1494 1.69217C67.1494 2.82425 67.1494 2.98772 67.2038 3.01125V3.03447H66.8692H66.5111V3.01125C66.5661 2.98772 66.5661 2.82425 66.5661 1.87886V1.74656H65.2709V1.87886C65.2709 2.82425 65.2709 2.98772 65.325 3.01125V3.03447H64.9867H64.6362V3.01125C64.6912 2.98772 64.6912 2.82425 64.6912 1.69217V1.26439C64.6912 0.210222 64.6912 0.04675 64.6405 0.0238333V0H64.9867H65.3213V0.0238333C65.2709 0.04675 65.2709 0.210222 65.2709 1.12811V1.24881H66.5661V1.12811C66.5661 0.210222 66.5661 0.04675 66.5191 0.0238333V0H66.8692H67.1962V0.0238333C67.1494 0.04675 67.1494 0.210222 67.1494 1.26439V1.69217Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M73.811 1.26414C73.811 0.210284 73.811 0.0468114 73.7642 0.0235892V-0.000244141H74.5654H75.3553C75.3553 0.17545 75.351 0.311423 75.351 0.540895H75.3278C75.3006 0.497811 75.1258 0.4862 74.8884 0.4862H74.3906V1.25284H74.8841C75.1258 1.25284 75.1991 1.24856 75.2266 1.22167H75.2462V1.48628V1.77014H75.2266C75.1991 1.73898 75.1218 1.73531 74.8923 1.73531H74.3906V2.47017C74.3906 2.50531 74.3943 2.52456 74.4175 2.54014C74.6195 2.54381 74.8884 2.54381 75.0671 2.54014C75.2306 2.54014 75.336 2.53251 75.3666 2.49339H75.3901C75.3864 2.72684 75.3864 2.85517 75.3864 3.03423H74.6277H73.7603V3.01131C73.811 2.98778 73.811 2.82401 73.811 1.69192V1.26414Z'
          fill='#0A0909'
          fillRule='evenodd'
        />
      </svg>
      <img
        alt='Election Wire'
        className={styles.desktopBanner}
        src='https://dailywireplus-v2.imgix.net/manual-uploads/election-hub-logo.png'
      />
      <img
        alt=''
        className={styles.mobileBanner}
        src='https://dailywireplus-v2.imgix.net/manual-uploads/mobile-border.png'
      />
      <p>
        Daily Wire&apos;s Election Hub is your source for exclusive coverage of the 2024 elections, including polling
        data, election maps, and analysis.
      </p>
      <a className={styles.anchorText} href='https://election.dailywire.com' rel='noreferrer' target='_blank'>
        EXPLORE LATEST
      </a>
    </a>
  );
};
