import React from 'react';

interface IframeComponentProps {
  src: string;
  title?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  allowFullScreen?: boolean;
  id?: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({
  src,
  title,
  style,
  width = '100%',
  height = '100%',
  allowFullScreen = false,
  id,
}) => {
  return (
    <iframe
      allowFullScreen={allowFullScreen}
      height={height}
      id={id}
      loading='lazy'
      src={src}
      style={{ ...style, border: 'none' }}
      title={title}
      width={width}
    />
  );
};

export default IframeComponent;
