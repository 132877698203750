import {
  AllAccessLiveEpisodePaywall,
  BenShapiroSBookClubEpisodePaywall,
  DefaultEpisodePaywall,
  TheDailyWireChannelEpisodePaywall,
  PragerUChannelEpisodePaywall,
  JordanPetersonChannelEpisodePaywall,
  DragonsMonstersMenEpisodePaywall,
} from '@/marketing/links';

import showListByChannelName from './sortedShowList';

const channelNameToSubscriptionUrlMap = new Map([
  ['daily-wire', TheDailyWireChannelEpisodePaywall.href],
  ['jordan-peterson', JordanPetersonChannelEpisodePaywall.href],
  ['prager-u', PragerUChannelEpisodePaywall.href],
  ['movies', '/subscribe-plus-movies'],
  ['kids', '/subscribe-plus-kids'],
]);

const showNameToChannelNameMap = Object.keys(showListByChannelName).reduce((map, channelName) => {
  (showListByChannelName[channelName] as string[]).forEach((showName) => {
    map.set(showName, channelName);
  });

  return map;
}, new Map<string, string>());

// NOTE: Some shows might have custom subscription pages for them,
// so this is the map to check out first (and fallback to the default behavior
// of checking the global channel-level sub otherwise).
const showNameToSubscriptionUrlMap = new Map([
  ['All Access Live', AllAccessLiveEpisodePaywall.href],
  ['Ben Shapiro’s Book Club', BenShapiroSBookClubEpisodePaywall.href],
  ['Dragons, Monsters, and Men', DragonsMonstersMenEpisodePaywall.href],
]);

// NOTE: This is temporary and we need the show-to-channel relation
// information to present on the show itself instead
export const resolvePaywallUrlByShowName = (showName?: string) => {
  // Check whether there's any show-specific subscription page
  if (showNameToSubscriptionUrlMap.has(showName)) {
    return showNameToSubscriptionUrlMap.get(showName);
  }

  // Then fallback to channel-level if there's no show-specific subscription page
  const channelName = showNameToChannelNameMap.get(showName);
  if (channelName && channelNameToSubscriptionUrlMap.has(channelName)) {
    return channelNameToSubscriptionUrlMap.get(channelName);
  }

  // If everything else fails, fallback to the most generic subscription page
  return DefaultEpisodePaywall.href;
};
