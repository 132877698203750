/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';
import NextLink from 'next/link';

import { NextSeo, ArticleJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { buildURL } from 'react-imgix';
import ReactModal from 'react-modal';
import { Container, Text } from '@/components/design-system';
import EpisodeLiveChat from '@/components/episodes/EpisodeLiveChatNew';
import EpisodeTitleAndByline from '@/components/episodes/full/EpisodeTitleAndByline';
import Main from '@/components/shared/Main';
import { useAuth0 } from '@/context/auth';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { ALL_ACCESS_LIVE_SHOW_SLUG, APP_STORE_ID, CHAT_API_URL, SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { useEpisode } from '@/request/episode';
import {
  stripHtml,
  isUpcomingDate,
  subscriberCanAccess,
  continentKeyToName,
  subscriberHasPaidPlan,
} from '@/utils/helpers';
import ElectionMaps from './electionday/ElectionMaps';
import ElectionArticles from './electionday/ElectionArticles';
import { appApi, EpisodeStatus } from '@/graphql/app';
import { chatApi } from '@/graphql/chat';
import {
  getLeadPosts,
  getFeaturedPosts,
  getRecentPosts,
  getCoverPosts,
  getAuthorWithPosts,
  getTrendingTopics,
} from '@/lib/wordpress';
import { GetStaticProps } from 'next';
import style from './Homepage.module.css';
import ElectionDayNav from './electionday/ElectionDayNav';
import { useLightModeToggle } from '@/context/LightModeToggleContext';
import ChatPaywall from '../episodes/chatPaywall';
import { resolvePaywallUrlByShowName } from '../version2/watch/utils';
import { useBreakpoints } from '@/hooks/useBreakpoints';

const ElectionPlayer = dynamic(() => import('@/components/episodes/ElectionPlayer'), { ssr: false });

const RECENT_POSTS_PAGE_SIZE = 16 * 3;

const HomepageElectionDay: any = ({ episodeData }) => {
  const { isTablet, isPhablet, isMobile: mobile } = useBreakpoints();
  const [mobileSize, setMobileSize] = React.useState(false);
  const router = useRouter();
  const { loading, subscriber } = useAuth0();
  const hasPaidPlan = subscriberHasPaidPlan(subscriber);

  const { data, mutate } = useEpisode(episodeData, episodeData?.episode?.slug);
  const [roomId, setRoomId] = React.useState('');
  const [roomStatus, setRoomStatus] = React.useState('open');
  const [, setEffectivePlan] = React.useState('');
  const [shouldBePIP, setShouldBePIP] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState<string | null>(null);

  const sectionRefs = {
    videoPlayer: React.useRef<HTMLDivElement>(null),
    electionNews: React.useRef<HTMLDivElement>(null),
    electionResults: React.useRef<HTMLDivElement>(null),
    liveBlog: React.useRef<HTMLDivElement>(null),
  };

  const episode = data.episode;
  const hasLiveChat = !loading && episode?.discussionId && episode.status !== EpisodeStatus.Scheduled;

  const videoContainerRef = React.useRef<HTMLDivElement>(null);
  const electionRef = React.useRef<HTMLDivElement>(null);

  const { isLightModeActive } = useLightModeToggle();
  const isHomePageElection = router.pathname === '/' && isLightModeActive;

  const firstSegment = episode?.segments?.[0];
  let liveChatAccess: string[] | null = null;

  if (
    firstSegment &&
    firstSegment.videoAccess &&
    Array.isArray(firstSegment.videoAccess) &&
    firstSegment.videoAccess.length > 0
  ) {
    liveChatAccess = firstSegment.videoAccess;
  }

  if (
    firstSegment &&
    firstSegment.liveChatAccess &&
    Array.isArray(firstSegment.liveChatAccess) &&
    firstSegment.liveChatAccess.length > 0
  ) {
    liveChatAccess = firstSegment.liveChatAccess;
  }

  const canLiveChat = liveChatAccess && subscriber && subscriberCanAccess(subscriber, liveChatAccess);

  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  const updateRoomStatus = (status) => {
    setRoomStatus(status);
  };

  const lc_nonce_provider = () => {
    const base = crypto.getRandomValues(new Uint8Array(1)).toString();
    let count = 0;
    return function () {
      count += 1;
      return `${base}-c${count}`;
    };
  };

  React.useEffect(() => {
    setMobileSize(isTablet || isPhablet || mobile);
  }, [isTablet, isPhablet, mobile]);

  const getRoomId = async (episode) => {
    // const path = document.location.pathname.toLowerCase().trim();
    const path = '/episode/' + episode?.slug;
    const roomHash = btoa(path);
    const nonce = lc_nonce_provider()();
    const promise = await fetch(
      CHAT_API_URL +
        '/getroom?' +
        new URLSearchParams({
          roompath: roomHash,
          nonce: nonce,
        }),
    ).catch((error) => console.log(error));
    if (promise) {
      const roomData = await promise?.json();
      if (roomData) {
        // Test Chat
        // setRoomId('3cbe5dc6-75ed-4466-aab0-70e2f5eb8125');
        // setRoomStatus('open');
        setRoomId(roomData.room_id);
        setRoomStatus(roomData.status);
      }
    }
  };

  React.useEffect(() => {
    if (!episode) return;
    getRoomId(episode);
  }, [episode]);

  React.useEffect(() => {
    if (episode) {
      window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
        ...abTestData,
        ...utmCookieData,
        title: episode?.title,
        program: episode?.show.name,
      });
    }
  }, [episode]);

  React.useEffect(() => {
    ReactModal.setAppElement('#__next');
    if (subscriber?.effectivePlan == 'ALL_ACCESS') {
      setEffectivePlan(subscriber.effectivePlan);
    }
  }, [subscriber]);

  // Track Paywall Views Event in Segment
  React.useEffect(() => {
    // We wait for user loading to complete to ensure this only fires once
    if (episode && !loading && hasLiveChat && !canLiveChat) {
      window.__DW_Next_Bridge?.Analytics.logEvent('Paywall Viewed', {
        ...abTestData,
        ...utmCookieData,
        content: 'Episode Live Chat',
        content_author: `${episode.createdBy.firstName} ${episode.createdBy.lastName} `,
        content_category: episode.show.name,
        content_id: episode.id,
        content_title: episode.title,
        content_type: 'episode',
      });
    }
  }, [loading, hasLiveChat, canLiveChat, episode]);

  const description = episode?.description ? stripHtml(episode.description) : '';
  const metaImageUrl = episode?.image ? buildURL(episode.image, { w: 1200, h: 675 }) : null;

  let metaImage: any;
  if (metaImageUrl) {
    metaImage = {
      url: metaImageUrl,
      width: 1200,
      height: 675,
      alt: episode?.title,
    };
  }

  const absUrl = `${SITE_URL}/episode/${episode?.slug}`;
  const parselyPubDate = episode?.createdAt ? new Date(episode.createdAt).toISOString().split('.')[0] + 'Z' : '';

  const isUpcomingEpisode = isUpcomingDate(episode.scheduleAt);
  const hasMultipleSegments = !episode.isLive && episode.segments && episode.segments.length > 1;

  const episodeDate = episode?.scheduleAt ? episode?.scheduleAt : episode?.createdAt;
  const showAvailableLocations = episode?.allowedContinents?.length !== 0 || episode?.allowedCountryNames?.length !== 0;

  React.useEffect(() => {
    if (!isHomePageElection) return;
    const handleScroll = (event) => {
      if (event.target.documentElement.scrollTop > 300) {
        setShouldBePIP(true);
      } else {
        setShouldBePIP(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomePageElection]);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const trackEvent = React.useCallback(
    (eventName, additionalData) => {
      window.__DW_Next_Bridge?.Analytics.logEvent(eventName, {
        ...abTestData,
        ...utmCookieData,
        label: 'Upgrade Now',
        location: 'Next Episode Autoplay',
        destination: 'https://www.get.dailywire.com',
        content_program: data.episode.show.name,
        gate_type: 'Paywall',
        content_name: data.episode.title,
        content_type: 'Video',
        ...additionalData,
      });
    },
    [abTestData, utmCookieData, data],
  );
  const handlePaywallCtaClick = React.useCallback(() => {
    trackEvent('Paywall CTA Clicked', null);
    router.push(resolvePaywallUrlByShowName(episode?.show?.name));
  }, [episode?.show?.name, router, trackEvent]);

  const handleSegmentClick = (label, destination) => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('CTA Click', {
      ...abTestData,
      ...utmCookieData,
      label: label,
      location: 'Election Home Page',
      destination: destination,
    });
  };

  React.useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.target.id === 'videoPlayer' && entry.isIntersecting) {
          setActiveSection(null);
        } else if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.4,
    });

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  return (
    <Main bg='#FFF'>
      <Head>
        <meta content='Episode' name='parsely-section' />
        <meta content='' name='parsely-tags' />
        <meta content={parselyPubDate} name='parsely-pub-date' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
      </Head>
      <NextSeo
        additionalMetaTags={[
          {
            name: 'apple-itunes-app',
            content: `app-id=${APP_STORE_ID}, app-argument=${absUrl}`,
          },
          {
            property: 'al:ios:url',
            content: absUrl,
          },
          {
            property: 'al:android:url',
            content: absUrl,
          },
          {
            name: 'twitter:app:url:iphone',
            content: absUrl,
          },
          {
            name: 'twitter:app:url:ipad',
            content: absUrl,
          },
          {
            name: 'twitter:app:url:googleplay',
            content: absUrl,
          },
        ]}
        description={description}
        openGraph={{
          url: absUrl,
          title: episode.title ?? '',
          description: description,
          images: [metaImage],
        }}
        title={episode.title ?? ''}
      />
      <ArticleJsonLd
        authorName={`${episode?.createdBy.firstName} ${episode?.createdBy.lastName}`}
        dateModified={episode?.updatedAt}
        datePublished={episode?.createdAt}
        description={description}
        images={metaImageUrl ? [metaImageUrl] : ['']}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={episode?.title ?? ''}
        url={absUrl}
      />
      <Container
        as='article'
        css={(theme) => ({
          display: 'grid',
          gridTemplateRows: '.9fr .1fr',
          columnGap: 48,
          rowGap: 20,
          backgroundColor: '#FFF',
          paddingBottom: '1rem',
          margin: '0 auto',
          boxSizing: 'border-box',
          [theme.mq.desktop]: {
            width: '100%',
            maxWidth: '1600px',
            columnGap: 48,
            paddingBottom: '5.5rem',
            gridTemplateColumns: roomStatus !== 'open' ? '.7' : '.60fr .40fr',
            gridTemplateRows: 'unset',
            position: 'relative',
          },
        })}
      >
        <div
          css={(theme) => ({
            gridRowStart: 1,
            gridRowEnd: 2,
            // gridColumn: roomStatus === 'open' ? '1' : '1/3',
            alignSelf: 'center',
            [theme.mq.desktop]: {
              gridRowStart: 1,
            },
          })}
          ref={sectionRefs.videoPlayer}
        >
          <div
            className={style.videoContainer}
            ref={videoContainerRef}
            style={{ position: 'relative', width: '100%', aspectRatio: '16/9', background: 'black' }}
          >
            <ElectionPlayer
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              episode={episode}
              fixedOnMobile={false}
              format='video'
              mutateEpisode={mutate}
              shouldBePIP={shouldBePIP}
              startMuted={false}
            />
          </div>
          {(showAvailableLocations || episode?.rating) && (
            <>
              <div
                css={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.colors.gray20,
                  marginBottom: '1.5rem',
                })}
              >
                {/* Rating */}
                {episode?.rating && (
                  <Text
                    css={(theme) => ({
                      border: `1px solid ${theme.colors.gray60}`,
                      color: theme.colors.gray20,
                      padding: '.25rem .5rem',
                      borderRadius: '.25rem',
                      marginRight: '.5rem',
                      flexShrink: 0,
                    })}
                    variant='100'
                  >
                    {episode.rating}
                  </Text>
                )}

                {showAvailableLocations && (
                  <Text variant='100'>
                    Available in{' '}
                    {episode.allowedContinents?.map((continentKey, index) => (
                      <React.Fragment key={continentKey}>
                        {continentKeyToName(continentKey)}
                        {episode.allowedContinents.length !== index + 1 && ', '}
                      </React.Fragment>
                    ))}
                    {episode.allowedCountryNames?.map((countryName, index) => (
                      <React.Fragment key={countryName}>
                        {countryName}
                        {episode.allowedCountryNames.length !== index + 1 && ', '}
                      </React.Fragment>
                    ))}
                  </Text>
                )}
              </div>
            </>
          )}
        </div>
        <div
          css={(theme) => ({
            gridRowStart: 2,
            gridRowEnd: 3,
            paddingTop: `${hasMultipleSegments ? '0' : '0'}`,
            [theme.mq.desktop]: {
              paddingLeft: 0,
              paddingRight: 0,
              color: theme.colors.black,
            },
          })}
        >
          <EpisodeTitleAndByline
            date={episodeDate}
            isElection={true}
            isPragerU={false}
            isUpcomingEpisode={isUpcomingEpisode}
            scheduleAt={episode?.scheduleAt}
            showName={episode?.show.name}
            showSlug={episode?.show.slug}
            textColor='black'
            title={episode?.title}
          />
        </div>
        {!isMobile && roomStatus == 'open' && (
          <div
            style={{
              position: 'relative',
            }}
          >
            <EpisodeLiveChat
              canLiveChat={!!canLiveChat}
              isElection={true}
              roomId={roomId}
              roomStatus={roomStatus}
              updateRoomStatus={updateRoomStatus}
            />
            {!canLiveChat && (
              <ChatPaywall
                accessLevels={firstSegment?.liveChatAccess}
                handlePaywallCtaClick={handlePaywallCtaClick}
                isLightModeActive={!!isLightModeActive}
                subscriber={subscriber}
              />
            )}
          </div>
        )}
      </Container>
      {isMobile && roomStatus == 'open' && (
        <div style={{ height: '25rem', position: 'relative' }}>
          <EpisodeLiveChat
            canLiveChat={!!canLiveChat}
            isElection={true}
            roomId={roomId}
            roomStatus={roomStatus}
            updateRoomStatus={updateRoomStatus}
          />
          {!canLiveChat && (
            <ChatPaywall
              accessLevels={firstSegment?.liveChatAccess}
              handlePaywallCtaClick={handlePaywallCtaClick}
              isLightModeActive={!!isLightModeActive}
              subscriber={subscriber}
            />
          )}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <div className={shouldBePIP || mobileSize ? 'electionNavFixed visible' : 'electionNav'}>
          <ElectionDayNav activeSection={activeSection} />
        </div>
      </div>
      <div
        className={style.ElectionArticlesContainer}
        id='election-news'
        ref={sectionRefs.electionNews}
        style={{ padding: `${hasPaidPlan ? '20px 0 40px' : '20px 0 20px'}` }}
      >
        <ElectionArticles />
        {hasPaidPlan ? (
          <div
            className='membership-ad'
            onClick={() =>
              handleSegmentClick('AIR Watch Now Member Banner', 'https://www.dailywire.com/videos/am-i-racist')
            }
          >
            {isMobile ? (
              <NextLink href='/videos/am-i-racist'>
                <img
                  alt='Exclusive Paid Member Ad'
                  src='https://dailywire-2-development.imgix.net/uploads/air-bed-banner-mobile.jpg'
                  style={{ borderRadius: '32px' }}
                />
              </NextLink>
            ) : (
              <NextLink href='/videos/am-i-racist'>
                <img
                  alt='Exclusive Paid Member Ad'
                  src='https://dailywireplus-v2.imgix.net/uploads/air-bed-banner-2x.jpg'
                  style={{ borderRadius: '32px', maxWidth: '1200px', width: '100%' }}
                />
              </NextLink>
            )}
          </div>
        ) : (
          //Non-Member Banner
          <div
            className='membership-ad'
            onClick={() => handleSegmentClick('47% Off Banner', 'https://get.dailywire.com/')}
          >
            {isMobile ? (
              <NextLink href='https://get.dailywire.com/'>
                <img
                  alt='Election Banner'
                  src='https://dailywireplus-v2.imgix.net/images/dailywire.com/47-mobile.png'
                />
              </NextLink>
            ) : (
              <NextLink href='https://get.dailywire.com/'>
                <img
                  alt='Election Banner'
                  src='https://dailywireplus-v2.imgix.net/uploads/47_off_2x.png'
                  style={{ borderRadius: '32px', maxWidth: '1200px', width: '100%' }}
                />
              </NextLink>
            )}
          </div>
        )}
      </div>
      <div
        id='election-results'
        ref={electionRef}
        style={{ paddingBottom: `${hasPaidPlan ? '10px' : '0'}`, backgroundColor: '#E0E0E0' }}
      >
        <div id='election-maps' ref={sectionRefs.electionResults}>
          <ElectionMaps />
        </div>
      </div>
      {hasPaidPlan ? (
        <div
          className='membership-ad'
          onClick={() =>
            handleSegmentClick('AIR Watch Now Member Banner', 'https://www.dailywire.com/videos/am-i-racist')
          }
          style={{ backgroundColor: '#F5F5F5' }}
        >
          {isMobile ? (
            <NextLink href='/videos/am-i-racist'>
              <img
                alt='Exclusive Paid Member Ad'
                src='https://dailywire-2-development.imgix.net/uploads/air-white-banner-mobile.jpg'
                style={{ borderRadius: '32px' }}
              />
            </NextLink>
          ) : (
            <NextLink href='/videos/am-i-racist'>
              <img
                alt='Exclusive Paid Member Ad'
                src='https://dailywireplus-v2.imgix.net/uploads/air-white-banner-2x.jpg'
                style={{ borderRadius: '32px', maxWidth: '1200px', width: '100%' }}
              />
            </NextLink>
          )}
        </div>
      ) : (
        //Non-Member Banner
        <div
          className='membership-ad'
          onClick={() => handleSegmentClick('AIR Banner', 'https://www.amiracist.com/')}
          style={{ backgroundColor: '#F5F5F5' }}
        >
          {isMobile ? (
            <a href='https://www.amiracist.com/' rel='noopener noreferrer' target='_blank'>
              <img alt='Election Banner' src='https://dailywireplus-v2.imgix.net/images/dailywire.com/air-mobile.png' />
            </a>
          ) : (
            <a href='https://www.amiracist.com/' rel='noopener noreferrer' target='_blank'>
              <img
                alt='Election Banner'
                src='https://dailywireplus-v2.imgix.net/uploads/air_now_streaming_2x.png'
                style={{ borderRadius: '32px', maxWidth: '1200px', width: '100%' }}
              />
            </a>
          )}
        </div>
      )}
    </Main>
  );
};

export default HomepageElectionDay;

export const getStaticProps: GetStaticProps = async (context) => {
  let discussions: any;

  const [
    leadPosts,
    featuredPosts,
    latestEpisodesData,
    allAccessLiveEpisodesData,
    openDiscussionsData,
    upcomingDiscussionsData,
    endedDiscussionsData,
  ] = await Promise.all([
    getLeadPosts(1),
    getFeaturedPosts(4),
    appApi.getEpisodes({ first: 12 }),
    appApi.getEpisodesByShow({ first: 12, showSlug: ALL_ACCESS_LIVE_SHOW_SLUG }),
    chatApi.getDiscussions({ first: 10, status: ['OPEN'] }),
    chatApi.getDiscussions({ first: 10, status: ['UPCOMING'] }),
    chatApi.getDiscussions({ first: 2, status: ['ENDED'] }),
  ]);

  const recentPosts = await getRecentPosts(RECENT_POSTS_PAGE_SIZE, 0, []);

  // NOTE: Currently stuck with this no-so-efficent 3-query due to API limitations
  if (openDiscussionsData && openDiscussionsData.discussions) {
    // side step destructive nature of array.reverse()
    const openReversed = [...openDiscussionsData.discussions].reverse();
    discussions = [...openReversed];
  }
  if (upcomingDiscussionsData && upcomingDiscussionsData.discussions) {
    // side step destructive nature of array.reverse()
    const upcomingReversed = [...upcomingDiscussionsData.discussions].reverse();
    discussions = [...discussions, ...upcomingReversed];
  }
  if (endedDiscussionsData && endedDiscussionsData.discussions) {
    discussions = [...discussions, ...endedDiscussionsData.discussions];
  }

  // Filtering out All Access Live
  const latestEpisodesFiltered = latestEpisodesData.episodes.filter(
    (episode) => episode?.show.slug !== ALL_ACCESS_LIVE_SHOW_SLUG,
  );

  // Step: 1 - Parallel fetching of all content *except* the readers pass carousel
  const [coverPosts, benShapiroPosts, candaceOwensPosts, jordanPetersonPosts, trendingTopics, parselyTopTopicsData] =
    await Promise.all([
      getCoverPosts(1),
      getAuthorWithPosts('ben-shapiro', 16),
      getAuthorWithPosts('candace-owens', 16),
      getAuthorWithPosts('jordan-peterson', 16),
      getTrendingTopics(4),
      appApi.getParselyTopTopics({ limit: 3 }),
    ]);
  // Step: 2 - postIds to exclude from cover, ben, candace, and morning wire posts
  const postIdsToExclude = [
    ...(coverPosts ?? []),
    ...(benShapiroPosts.posts ?? []),
    ...(candaceOwensPosts.posts ?? []),
    ...(jordanPetersonPosts.posts ?? []),
  ].map((p) => p.id);

  // Step: 3 - the 16 latest readers pass posts, excluding step 2 ids
  const readersPassPosts = await getRecentPosts(6, 0, postIdsToExclude, { members_only: 'true' });
  const slug = context.params?.slug as string;
  const episodeData = await appApi.getEpisodeBySlug({ slug: 'ep-2060-member-exclusive' });

  if (!episodeData?.episode || slug == 'terror-on-the-prairie-world-premiere') {
    return {
      props: {
        episodeData: episodeData,
      },
      notFound: true,
      revalidate: 600,
    };
  }
  return {
    props: {
      episodeData: episodeData,
      leadPosts: leadPosts,
      featuredPosts: featuredPosts,
      recentPosts: recentPosts,
      latestEpisodes: latestEpisodesFiltered,
      allAccessLiveEpisodes: allAccessLiveEpisodesData.episodes,
      discussions: discussions,
      coverPosts: coverPosts ?? [],
      readersPassPosts: readersPassPosts ?? [],
      benShapiroPosts: benShapiroPosts.posts ?? [],
      candaceOwensPosts: candaceOwensPosts.posts ?? [],
      jordanPetersonPosts: jordanPetersonPosts.posts ?? [],
      trendingTopics: trendingTopics ?? [],
      parselyTopTopics: parselyTopTopicsData.topics ?? [],
    },
    revalidate: 600, // 10 mins (in seconds)
  };
};
