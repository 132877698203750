/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as React from 'react';

import { useBreakpoints } from '@/hooks/useBreakpoints';

import styles from './ElectionDay.module.css';

const ElectionDayNav = ({ activeSection }) => {
  const { isMobile, isPhablet, isTablet } = useBreakpoints();
  const [mobileView, setMobileView] = React.useState(false);
  const handleScroll = (e, target) => {
    e.preventDefault();
    document.getElementById(target).scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    setMobileView(isTablet || isPhablet || isMobile);
  }, [isTablet, isPhablet, isMobile]);

  const handleScrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className={styles.electionNavContainer}>
      <ul className={styles.electionNavList}>
        <li className={styles.electionNavImg}>
          <a href='#' onClick={handleScrollToTop}>
            <div className={styles.svgWrapper}>
              <svg fill='none' height='16' viewBox='0 0 24 16' width='24' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M7.46374 3.95515C7.46374 2.97344 6.93007 1.83953 5.58827 1.83953C4.42945 1.83953 3.68994 2.66143 3.68994 3.87144C3.68994 4.54114 3.97964 4.99014 3.97964 5.61417C3.97964 6.61871 3.04191 7.02205 2.23378 7.02205C1.02922 7.02205 0.152477 6.20016 0.152477 4.72378C0.152477 2.28853 2.52349 0.796936 5.78649 0.796936C8.78266 0.796936 11.1689 2.06784 11.1689 4.67812C11.1689 8.96265 4.2846 9.20617 1.82972 12.3263H6.67848C9.00375 12.3263 10.0558 12.0143 10.3684 10.8347H11.3519L10.9707 12.958C10.7039 14.4496 10.9478 14.8529 11.0622 15.0127H0C0 9.34315 7.46374 7.6537 7.46374 3.97038V3.95515Z'
                  fill='#172E39'
                />
                <path
                  d='M11.3521 7.98858C11.3521 4.06174 13.7688 0.781754 17.6798 0.781754C21.5909 0.781754 24 4.06174 24 7.98858C24 11.9154 21.5832 15.2182 17.6798 15.2182C13.7764 15.2182 11.3521 11.9383 11.3521 7.98858ZM17.8781 13.993C19.9746 13.993 20.4702 11.2686 20.4702 8.57456C20.4702 5.18043 19.6849 2.0146 17.4282 2.0146C15.3469 2.0146 14.859 4.82275 14.859 7.45587C14.859 10.8043 15.6443 13.993 17.8781 13.993Z'
                  fill='#172E39'
                />
              </svg>
              <svg fill='none' height='16' viewBox='0 0 24 16' width='24' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_1799_8462)'>
                  <path
                    d='M7.70357 3.93136C7.70357 2.91811 7.15276 1.74776 5.76785 1.74776C4.57178 1.74776 3.80851 2.59606 3.80851 3.84496C3.80851 4.53618 4.10752 4.99961 4.10752 5.64369C4.10752 6.68051 3.13966 7.09681 2.30556 7.09681C1.06229 7.09681 0.157377 6.24851 0.157377 4.72469C0.157377 2.21118 2.60458 0.671661 5.97243 0.671661C9.06488 0.671661 11.5278 1.9834 11.5278 4.67757C11.5278 9.09977 4.42228 9.35112 1.88852 12.5715H6.89309C9.29308 12.5715 10.379 12.2495 10.7016 11.032H11.7167L11.3232 13.2235C11.0478 14.763 11.2996 15.1793 11.4177 15.3443H0C0 9.4925 7.70357 7.74876 7.70357 3.94708V3.93136Z'
                    fill='#172E39'
                  />
                  <path
                    d='M18.1455 12.7677V11.4795H12.0787L11.5908 9.72793L18.2321 0.655731L21.6 1.0249V8.91889H23.9999V11.4717H21.6V12.7598C21.6 13.8674 22.0642 14.2365 23.4255 14.2994V15.3126H16.2728V14.2994C17.6813 14.2287 18.1455 13.8595 18.1455 12.7598V12.7677ZM14.4551 8.92674H18.1455V2.52515L14.038 8.11771C13.7154 8.55757 13.92 8.92674 14.4551 8.92674Z'
                    fill='#172E39'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1799_8462'>
                    <rect fill='white' height='14.6885' transform='translate(0 0.655731)' width='24' />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </a>
        </li>
        {!mobileView && <span style={{ opacity: '0.56' }}>|</span>}
        <li className={`${activeSection === 'election-news' ? styles.electionNavItemActive : styles.electionNavItem}`}>
          <a href='#' onClick={(e) => handleScroll(e, 'election-news')}>
            {mobileView ? 'News' : 'Election News'}
          </a>
        </li>
        <li className={`${activeSection === 'election-maps' ? styles.electionNavItemActive : styles.electionNavItem}`}>
          <a href='#' onClick={(e) => handleScroll(e, 'election-results')}>
            {mobileView ? 'Results' : 'Election Results'}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default ElectionDayNav;
