import * as React from 'react';

import styles from './ElectionDay.module.css';
import IframeComponent from './NationalEmbed';

type MapShape = {
  mapId: string;
  mapSrc: string;
  btnText: string;
};

const maps: MapShape[] = [
  {
    mapId: 'e-oq4S',
    mapSrc:
      'https://e.ddhq.io/v4/e/?c=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.IntcInJhY2VJRHNcIjpbNTI4MTIsNTI4MzMsNTI4MjEsNTI3OTcsNTI4MTMsNTI3OTQsNTI4MDIsNTI4NDAsNTI4MjksNTI4NDMsNTI4MjcsNTI4MTQsNTI4MjAsNTI4NDIsNTI4MzgsNTI4MTgsNTI4MDksNTI4NDEsNTI4MDYsNTI4MTAsNTI4MjgsNTI4MjYsNTI4MzQsNTI3OTksNTI4MzAsNTI4MTcsNTI4MDcsNTI3OTYsNTI4MzksNTI4MDUsNTI4MzIsNTI4MDMsNTI4MDgsNTI4MzUsNTI4MjUsNTI3OTUsNTI4MTEsNTI4MDAsNTI4MzYsNTI4MTksNTI4MTUsNTI3OTMsNTI4MzEsNTI4MjIsNTI4MTYsNTI4MDEsNTI4MjMsNTI4MjQsNTI4MzcsNTI3OTgsNTI4MDRdfSI.fHKM1awyVg_2TwTe3S50DFXLoCs4P8Q4wuGmphEewIjSzenzwL4r75wPVh31LZZhJTP7Ry5THp0eR1_0ChPjdg&id=e-oq4S',
    btnText: 'Presidential Race',
  },
  {
    mapId: 'e-Mp1B',
    mapSrc:
      'https://e.ddhq.io/v4/e/?c=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.IntcInJhY2VJRHNcIjpbNTI4NDcsNTI4NTMsNTI4NjAsNTI4NTcsNTI4NDUsNTI4NjMsNTI4ODEsNTI4NjYsNTI4NDksNTI4NjQsNTI4NDYsNTI4NzIsNTI4NTgsNTI4NDQsNTI4NjUsNTI4NzMsNTI4NTYsNTI4NTUsNTI4NTAsNTI4NzEsNTI4NjIsNTI4NjcsNTI4ODUsNTI4NzQsNTI4NzYsNTI4NzUsNTI4ODIsNTI4NjksNTI4NzgsNTI4ODQsNTI4NDgsNTI4ODAsNzUzNjIsNTI4NTIsNTI4NTRdfSI.isnBei79quNvq_QKLlDc3lHALJlzAUiOLFAh3FJ7siI-YkWOeWUPPmZREO0cJs52aXSWSWdd0b_JQjyqpHRxBA&id=e-Mp1B',
    btnText: 'Senate Race',
  },
  {
    mapId: 'e-VOrs',
    mapSrc:
      'https://e.ddhq.io/v4/e/?c=eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.IntcInJhY2VzQmxvYlBhdGhcIjpcIi8yMDI0LzNcIn0i.4aaM0zo5IvDDCbZgTM8F7ub-FP6JzGszrX2voEw3-oNy-QL8gLNLOAGaRC_JN-aZM2tjZvbNuvkhAI-iJ8hqEQ&id=e-VOrs',
    btnText: 'House Race',
  },
];

const ElectionMaps: React.FC = () => {
  const presidentialMap = maps[0];
  const { mapId, mapSrc } = presidentialMap;
  const [isHovered, setIsHovered] = React.useState(false);

  const handleHoverPolicies = () => {
    setIsHovered(true);
  };

  const handleLeavePolicies = () => {
    setIsHovered(false);
  };

  return (
    <div className={styles.electionMaps}>
      <h3 className={styles.ElectionNightHeader}>
        ELECTION<span>RESULTS</span>
      </h3>
      {/* Presidential Map */}
      <div className={styles.presidentialMap}>
        {/* Remove style when changing to election day maps */}
        <IframeComponent id={mapId} src={mapSrc} style={{ maxWidth: 1100, minHeight: 0 }} />
      </div>
      <div className={styles.ddhqNoticeContainer} style={{ position: 'relative' }}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p
          className={styles.electionPolicies}
          onBlur={handleLeavePolicies}
          onFocus={handleHoverPolicies}
          onMouseOut={handleLeavePolicies}
          onMouseOver={handleHoverPolicies}
          style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}
          tabIndex={0}
        >
          Read our <span>election result policies</span>
        </p>
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              top: '12px',
              left: '0',
              marginTop: '5px',
              padding: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              color: 'white',
              borderRadius: '5px',
              width: '300px',
              zIndex: 1,
            }}
          >
            This map is powered by election results from our partners at Decision Desk HQ, and is not impacted by
            editorial decisions made by The Daily Wire. The Daily Wire does not function as a decision desk, and will
            assess race calls made by entities that do to guide our coverage.
          </div>
        )}
        <div style={{ marginBottom: '20px' }}>
          <p className={styles.ddhqNoticeText}>Data provided by</p>
          <img alt='DDHQ Notice' src='/election-night/ddhq-notice.png' />
        </div>
      </div>

      {/* Senate and House Maps */}
      <div className={styles.mapsContainer}>
        <div className={styles.maps}>
          {/* Senate Map */}
          <div className={styles.map}>
            <IframeComponent id={maps[1].mapId} src={maps[1].mapSrc} />
          </div>
          {/* House Map */}
          <div className={styles.map}>
            <IframeComponent id={maps[2].mapId} src={maps[2].mapSrc} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectionMaps;
