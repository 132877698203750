/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';

import styles from './index.module.css';

export default function chatPaywall({ subscriber, accessLevels, handlePaywallCtaClick, isLightModeActive = false }) {
  return (
    <div className={`${isLightModeActive ? styles.paywallBoxElection : styles.paywallBox}`}>
      <svg fill='none' height='32' viewBox='0 0 33 32' width='33' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.5 14.75V9.75C11.5 8.42392 12.0268 7.15215 12.9645 6.21447C13.9021 5.27678 15.1739 4.75 16.5 4.75C17.8261 4.75 19.0979 5.27678 20.0355 6.21447C20.9732 7.15215 21.5 8.42392 21.5 9.75V14.75M7.75 17.25C7.75 16.5869 8.01339 15.951 8.48223 15.4822C8.95107 15.0134 9.58696 14.75 10.25 14.75H22.75C23.413 14.75 24.0489 15.0134 24.5178 15.4822C24.9866 15.951 25.25 16.5869 25.25 17.25V24.75C25.25 25.413 24.9866 26.0489 24.5178 26.5177C24.0489 26.9866 23.413 27.25 22.75 27.25H10.25C9.58696 27.25 8.95107 26.9866 8.48223 26.5177C8.01339 26.0489 7.75 25.413 7.75 24.75V17.25ZM15.25 21C15.25 21.3315 15.3817 21.6495 15.6161 21.8839C15.8505 22.1183 16.1685 22.25 16.5 22.25C16.8315 22.25 17.1495 22.1183 17.3839 21.8839C17.6183 21.6495 17.75 21.3315 17.75 21C17.75 20.6685 17.6183 20.3505 17.3839 20.1161C17.1495 19.8817 16.8315 19.75 16.5 19.75C16.1685 19.75 15.8505 19.8817 15.6161 20.1161C15.3817 20.3505 15.25 20.6685 15.25 21Z'
          stroke={`${isLightModeActive ? '#172E39' : '#8F8F8F'}`}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </svg>
      <p>
        Join the live chat as a DW+{' '}
        {accessLevels?.includes('INSIDER') || accessLevels?.includes('INSIDER_PLUS')
          ? 'Insider or All Access'
          : 'All Access'}{' '}
        Member
      </p>
      <button onClick={handlePaywallCtaClick}>{subscriber ? 'Upgrade' : 'Browse Plans'}</button>
    </div>
  );
}
